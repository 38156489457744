<template>
  <div class="section-header" :class=" { 'short': !description && !progress }">

    <div>
      <!-- Breadcrumbs -->
      <div class="breadcrumbs-container pb-2 mb-3" v-if="false && breadcrumbsPresent">
        <!-- <b-button variant="link" @click="$router.back()">
          <b-icon icon="arrow-left" variant="primary"></b-icon>
          Atrás
        </b-button> -->
        <router-link :to="{ path: breadcrumb.path, params: { name: breadcrumb.name }}" v-for="(breadcrumb, index) in breadcrumbs" v-bind:key="breadcrumb.name">{{ breadcrumb.name }} {{ (index !== breadcrumbs.length - 1) ? ' > ' : '' }}</router-link>
      </div>

      <SectionHeaderSkeleton class="w100p" v-if="loading" />

      <b-row v-else>
        <b-col md="12" lg="9">
          <div class="flex section-content inline-wrapper p-relative">
            <a class="clickable back-link mr-3" @click="$router.back()" style="line-height: 80px;" v-if="hasBackButton">
              <b-icon icon="arrow-left-circle-fill" variant="primary" class="mr-2 mt-2" font-scale="2"/>
            </a>
            <b-row>
              <b-col>
                <h3>
                  {{ name }}
                </h3>

                <p class="section-description body-2" v-if="description" v-html="description" />
              </b-col>
            </b-row>
          </div>
        </b-col>

        <b-col md="12" lg="3">
          <ProgressCard label="% de tema completado" :value="progress" style="float: right" v-if="progress"></ProgressCard>
          <slot name="extra" />
        </b-col>
      </b-row>
    </div> <!-- Cierre main-container -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ProgressCard from '@/components/utils/cards/ProgressCard'
import SectionHeaderSkeleton from '@/components/skeletons/SectionHeaderSkeleton'

export default {
  name: 'SectionHeader',
  components: { ProgressCard, SectionHeaderSkeleton },
  props: {
    name: { type: String, required: false, default: '' },
    description: { type: String, required: false, default: '' },
    progress: { type: Number, required: false, default: 0 },
    hasBackButton: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false }
  },
  data () {
    return { }
  },
  computed: {
    ...mapGetters({
      breadcrumbs: 'navigation/getBreadcrumbs'
    }),
    breadcrumbsPresent () { return this.breadcrumbs && this.breadcrumbs.length > 0 }
  }

}
</script>

<style lang="scss" scoped>
.section-header {
  background-color: white;
  padding: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid $grey;

  h3 {
    word-wrap: break-word;
  }

  &.short {
    padding-bottom:8px
  }

  .breadcrumbs-container {
    border-bottom: 1px solid $grey;

    & > *:hover {
      color: $primary;
      cursor: pointer;
    }
  }
}

</style>
