<template>
  <div>
    <b-skeleton width="30%" height="3rem"></b-skeleton>
    <b-skeleton class="mt-3" width="45%"></b-skeleton>
    <b-skeleton width="25%"></b-skeleton>
  </div>
</template>

<script>
export default {
  name: 'SectionHeaderSkeleton'
}
</script>
