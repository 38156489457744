<template>
  <div>
    <loading :loading="isLoading"></loading>
    <SectionHeader
    :name="$t('PILLS.list')"
    :description="$t('PILLS.list_description')"
    :loading="isLoading"
    />

    <b-row class="main-container py-5 px-md-5 overflow-x-hidden">
      <b-col lg="2" sm="12" class="mb-3">
        <Card no-shadow>
          <template v-slot:content>
            <!-- <h1>Filtros</h1> -->

            <b-row>
              <b-col cols="6" md="12">
                <b-form-group
                  :id="`input-group-difficulty`"
                  :label="$t('PILLS.difficulty')"
                  class="mb-4"
                >
                  <b-form-checkbox-group
                    v-model="params.difficulty"
                    :options="options.difficulty"
                    text-field="label"
                    value-field="value"
                    name="difficulty"
                    size="md"
                    stacked
                  ></b-form-checkbox-group>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="12">
                <b-form-group
                  :id="`input-group-type`"
                  :label="$t('PILLS.type')"
                  label-for="input-1"
                  class="mb-4"
                >
                  <b-form-checkbox-group
                    v-model="params.type"
                    :options="options.type"
                    text-field="label"
                    value-field="value"
                    name="type"
                    size="md"
                    stacked
                  ></b-form-checkbox-group>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="12">
                <b-form-group
                  :id="`input-group-status`"
                  :label="$t('PILLS.status')"
                  class="mb-2"
                >
                  <b-form-checkbox-group
                    v-model="params.status"
                    :options="options.status"
                    text-field="label"
                    value-field="value"
                    name="status"
                    size="md"
                    stacked
                  ></b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
        </Card>
      </b-col>
      <b-col>
        <PillsTable :items="filteredPills"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SectionHeader from '@/components/SectionHeader'
import PillsTable from '@/components/tables/Pills'

import mapper from '@/services/mixins/mapper.mixins'
import { DIFFICULTIES, PILL_STATUS, PILL_TYPES } from '@/config/constants'

import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  components: { SectionHeader, PillsTable },
  mixins: [i18nmixin, mapper],
  data () {
    return {
      params: {
        type: [],
        difficulty: [],
        status: []
      },
      options: {
        type: [
          { label: this.getPillTypeDisplayName(PILL_TYPES.THEORY), value: PILL_TYPES.THEORY },
          { label: this.getPillTypeDisplayName(PILL_TYPES.PRACTICE), value: PILL_TYPES.PRACTICE }
        ],
        status: [
          { label: this.getPillStatusDisplayName(PILL_STATUS.PENDING), value: PILL_STATUS.PENDING },
          { label: this.getPillStatusDisplayName(PILL_STATUS.IN_PROGRESS), value: PILL_STATUS.IN_PROGRESS },
          { label: this.getPillStatusDisplayName(PILL_STATUS.COMPLETE), value: PILL_STATUS.COMPLETE }
        ],
        difficulty: [
          { label: this.getDifficultyDisplayName(DIFFICULTIES.VERY_LOW), value: DIFFICULTIES.VERY_LOW },
          { label: this.getDifficultyDisplayName(DIFFICULTIES.LOW), value: DIFFICULTIES.LOW },
          { label: this.getDifficultyDisplayName(DIFFICULTIES.MEDIUM), value: DIFFICULTIES.MEDIUM },
          { label: this.getDifficultyDisplayName(DIFFICULTIES.HARD), value: DIFFICULTIES.HARD },
          { label: this.getDifficultyDisplayName(DIFFICULTIES.EXTREME), value: DIFFICULTIES.EXTREME }
        ]
      }
    }
  },
  methods: {
    ...mapActions({
      getPills: 'pills/getPills'
    }),
    getPillStatus (pill) {
      if (typeof pill.student_pill_completion === 'undefined') return PILL_STATUS.PENDING

      return pill.student_pill_completion.completion_status
    }
  },
  computed: {
    ...mapGetters({
      pills: 'pills/getPills',
      isLoading: 'pills/isLoading'
    }),
    isFilteringByType () { return this.params.type.length > 0 },
    isFilteringByDifficulty () { return this.params.difficulty.length > 0 },
    isFilteringByStatus () { return this.params.status.length > 0 },
    translatedFilteredPills () {
      return this.translateObjectArray(this.filteredPills)
    },
    filteredPills () {
      return this.pills.filter(pill => {
        return (!this.isFilteringByType || this.params.type.indexOf(pill.type) !== -1) &&
        (!this.isFilteringByDifficulty || this.params.difficulty.indexOf(pill.difficulty) !== -1) &&
        (!this.isFilteringByStatus || this.params.status.indexOf(this.getPillStatus(pill)) !== -1)
      })
    }
  },
  mounted () {
    this.getPills(this.params)
  }
}
</script>
