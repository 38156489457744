<template>
  <Card class="progress-card" style="display: inline-block;" :no-shadow=true>
    <template v-slot:header>
      <span class="font-weight-semibold">Tu progreso</span>
    </template>

    <template v-slot:content>
      <div class="p-relative body">
        <span>{{ label }}</span>
        <span class="progress-value-label">{{ value }}%</span>
      </div>

      <b-progress :value="value"></b-progress>
    </template>
  </Card>
</template>

<script>
export default {
  name: '',
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: Number,
      required: false,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-card {
  width: 300px;
}

.body  {
  font-size: 0.7rem;
}

.progress-value-label {
  position: absolute;
  right: 0;
  bottom: 2px;
}
</style>
